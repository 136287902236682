import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

// material-ui
import { CssBaseline, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

// project imports
import Breadcrumbs from '../../components/extended/Breadcrumbs'
import { drawerWidth } from '../../utils/constant'
import navigation from '../menu-items/MenuItem'
import Header from './header'
import Sidebar from './sidebar'

// assets
import AdjustIcon from '@mui/icons-material/Adjust'

// styles
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background: 'linear-gradient(to right, #ffffff, #ffffff)'
  //background: 'linear-gradient(to right, #ff00f9, #813dfe)'
})

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({ 
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: 'white',
      // backgroundImage:
      //   'https://cdn.whilter.com/website/images/thumbnil/landingvideo-thumbnil.png',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px',
      },
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    }),
  })
)

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ userInfo }) => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

  // Handle left drawer
  const [open, setOpen] = useState(false)
  const handleDrawerToggle = () => {
    setOpen(open => !open)
  }

  useEffect(() => {
    setOpen(open => !matchDownMd)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd])

  return (
    <RootStyle>
      <CssBaseline />
      {/* header */}

      {/* <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} /> */}
      <Header
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        userInfo={userInfo}
      />

      {/* drawer */}
      <Sidebar drawerOpen={open} drawerToggle={handleDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={open} sx={{}}>
        {/* breadcrumb */}
        <Breadcrumbs
          separator={AdjustIcon}
         // navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
      </Main>
      {/* <Customization /> */}
    </RootStyle>
  )
}

export default MainLayout
