import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import MuiTableCell from './MuiTableCell'

const MuiTableBody = ({
  selected,
  setSelected,
  page,
  rowsPerPage,
  filteredList,
  records,
  keyField,
  showCheckbox = false,
  columns,
}) => {
  const handleClick = (event, item) => {
    const selectedIndex = selected.indexOf(item)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0

  return (
    <TableBody>
      {filteredList?.map((row ,index)=> {
        console.log("index",index,row)
        // const { id, name, role, status, company, avatarUrl, isVerified } = row
        const isItemSelected = selected.indexOf(row[keyField]) !== -1
        return (
          <TableRow
            hover
            key={row?.id || index}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            {showCheckbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  onChange={event => handleClick(event, row[keyField])}
                />
              </TableCell>
            )}
            {/* <TableCell component="th" scope="row" padding="none">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar alt={name} src={avatarUrl} />
                  <Typography variant="subtitle2" noWrap>
                    {name}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">{company}</TableCell>
              <TableCell align="left">{role}</TableCell>
              <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
              <TableCell align="left">
                <Label
                  variant="ghost"
                  color={(status === 'banned' && 'error') || 'success'}
                >
                  {sentenceCase(status)}
                </Label>
              </TableCell>

              <TableCell align="right">
                <MuiTableMoreMenu />
              </TableCell> */}

            {columns.map((column, columnIndex) => {
              // <TableCell align={column.align || 'left'} key={column.id}>
              //   {/* {column.formatter? column.formatter(row, column.formatExtraData): row[column.id]} */}
              //   {column.formatter ? (
              //     <column.formatter
              //       row={row}
              //       formatExtraData={column.formatExtraData}
              //     />
              //   ) : (
              //     row[column.id]
              //   )}
              //   {/* {column.formatter? "formatter": row[column.id]} */}
              // </TableCell>
              return (
                <MuiTableCell
                  key={column.id}
                  row={row}
                  column={column}
                  columnIndex={columnIndex}
                />
              )
            })}
          </TableRow>
        )
      })}
      {/* {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )} */}
    </TableBody>
  )
}

export default MuiTableBody
