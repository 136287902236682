import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { AppBar, Toolbar, Box, IconButton } from '@mui/material'

// project imports
import LogoSection from '../logo-section'
import SearchSection from './search-section'
import ProfileSection from './profile-section'

// assets
import MenuIcon from '@mui/icons-material/Menu'
import { drawerWidth } from '../../../utils/constant'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ drawerOpen, handleDrawerToggle, userInfo }) => {
  const theme = useTheme()
  const iconBackColor = theme.palette.white
  const iconBackColorOpen = theme.palette.secondary.main

  return (
    <>
      {/* logo & toggler button */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: '#080b28',
          // bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: drawerWidth - 40,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                width: 'auto',
              },
            }}
          >
            <IconButton
              disableRipple
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              color="secondary"
              sx={{
                borderRadius: 1,
                color: theme.palette.secondary.main,
                bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor,
                ml: { xs: 0, lg: -2 },
                '&:hover': {
                  background: theme.palette.secondary.main,
                  color: theme.palette.primary.light,
                },
              }}
            >
              {/* {!drawerOpen ? (
                <MenuIcon stroke={1.5} size="1.3rem" />
              ) : (
                <MenuIcon stroke={1.5} size="1.3rem" />
              )} */}
              <MenuIcon stroke={1.5} size="1.3rem" />
            </IconButton>
            <Box component="div">
              <LogoSection />
            </Box>
          </Box>

          {/* header search */}
          {/* <SearchSection /> */}
          <Box sx={{ flexGrow: 1 }} />

          {/* profile */}
          <ProfileSection userInfo={userInfo} />
        </Toolbar>
      </AppBar>
    </>
  )
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
}

export default Header
