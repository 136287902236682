import React, { lazy, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import MainLayout from '../layout/main-layout'
import { Protected } from './Protected'
import Loadable from './../components/Loadable'
import { NotFound } from '../features/pages/not-found'
import AssetNewPage from '../features/asset/asset-new'
import AssetBulkPage from '../features/asset/asset-bulk'
import MyAssetBulkPage from '../features/myasset/myasset-bulk'
import Subcategory from '../features/subcategory'
import AssetInfoEdit from '../features/asset/asset-edit/components/StatusReview/AssetInfoEdit'
import { connect } from 'react-redux'
import { EnumDataAction } from '../features/auth/redux/authAction'


// dashboard routing
const Dashboard = Loadable(lazy(() => import('./../features/pages/dashboard')))


// // sample page routing
const SamplePage = Loadable(
  lazy(() => import('./../features/pages/sample-page'))
)

const CategoryList = Loadable(lazy(() => import('../features/category')))
const VendorList = Loadable(lazy(() => import('../features/vendor')))
const AssetList = Loadable(lazy(() => import('../features/asset/asset-list')))
const ViewAssets = Loadable(
  lazy(() =>
    import('../features/asset/asset-edit/components/StatusReview/ViewAssets')
  )
)
const RolesPage = Loadable(lazy(() => import('../features/auth/RolesPage')))
const FileUpload = Loadable(
  lazy(() => import('./../features/asset/asset-form/components/FileUpload'))
)
const UploadVideoClip = Loadable(
  lazy(() => import('./../features/videoclip/components/UploadVideoClip'))
)
const ClipMetaData = Loadable(
  lazy(() => import('./../features/videoclip/components/ClipMetaData'))
)
const VideoClipList = Loadable(
  lazy(() => import('./../features/videoclip/VideoClipList'))
)

const ImageList = Loadable(
  lazy(() => import('./../features/image-library/image-list'))
)

//My Asset routings
const MyAssetList = Loadable(
  lazy(() => import('../features/myasset/myasset-list'))
)

const MyAssetPage = Loadable(
  lazy(() => import('../features/myasset/myasset-new'))
)

const MyAssetEditPage = Loadable(
  lazy(() => import('../features/myasset/myasset-edit/components/StatusDraft'))
)

const MyAssetAnalysingPage = Loadable(
  lazy(() =>
    import('../features/myasset/myasset-edit/components/StatusAnalysing')
  )
)
const MyAssetInReviewPage = Loadable(
  lazy(() =>
    import(
      '../features/myasset/myasset-edit/components/StatusReadyForReview/index'
    )
  )
)

const MyAssetInfoEdit = Loadable(
  lazy(() =>
    import(
      '../features/myasset/myasset-edit/components/StatusReadyForReview/MyAssetInfoEdit'
    )
  )
)
//Upcoming-Events routings
const EventsPage = Loadable(
  lazy(() => import('../features/upcoming-events/index'))
)
const EventForm = Loadable(
  lazy(() =>
    import('../features/upcoming-events/calender-section/event-form/index')
  )
)

//Copyright-content routings
const ContentList = Loadable(
  lazy(() => import('../features/copyright-content/content-list'))
)
const ContentCreatePage = Loadable(
  lazy(() => import('../features/copyright-content/content-create'))
)

//font-list
const FontList = Loadable(
  lazy(() => import('../features/font-library/font-list/index'))
)

const ContentEditPage = Loadable(
  lazy(() => import('../features/copyright-content/content-edit'))
)

const ImageEditPage = Loadable(
  lazy(() => import('../features/image-library/image-edit/index'))
)
//Trending-topics routings

const TrendingTopicsPage = Loadable(
  lazy(() => import('../features/trending-topics/index'))
)
//Template routings
const TemplateList = Loadable(
  lazy(() => import('../features/template/template-list/index'))
)
const TemplateNew = Loadable(
  lazy(() => import('../features/template/template-new'))
)
const TemplateNewSource = Loadable(
  lazy(() => import('../features/template/template-new/TemplateNewSource'))
)
const TemplateNewOrientation = Loadable(
  lazy(() => import('../features/template/template-new/TemplateNewOrientation'))
)
const UpcomingTemplateList = Loadable(
  lazy(() => import('../features/template/template-list/category-list'))
)

const DraftTemplatePage = Loadable(
  lazy(() => import('../features/template/template-edit/status-draft'))
)

const TemplateInstances = Loadable(
  lazy(() =>
    import('../features/template/template-list/components/TemplateInstances')
  )
)

const TemplateInstanceAdd = Loadable(
  lazy(() => import('../features/template/template-instance-new/index'))
)


const UpcomingTemplateEdit = Loadable(
  lazy(() =>
    import('../features/template/template-edit/status-processed/index')
  )
)

const TemplateInfoEdit = Loadable(
  lazy(() =>
    import(
      '../features/template/template-edit/status-processed/components/TemplateInfoEdit'
    )
  )
)

const SubCategoryList = Loadable(lazy(() => import('../features/subcategory')))
const TagsForm = Loadable(
  lazy(() => import('./../features/asset/asset-form/components/TagsForm'))
)
const PublicAssetTabs = Loadable(
  lazy(() =>
    import(
      '../features/asset/asset-edit/components/StatusReview/PublicAssetTabs'
    )
  )
)
const InReviewAssetEditPage = Loadable(
  lazy(() => import('../features/asset/asset-edit/components/LandscapeTab'))
)
const AnalysingPage = Loadable(
  lazy(() => import('../features/asset/asset-edit/components/StatusAnalysing'))
)

const AssetEditPage = Loadable(
  lazy(() =>
    import('../features/asset/asset-edit/components/StatusDraft/AssetEditPage')
  )
)

const CreateSegment = Loadable(() =>
  import('../features/asset/asset-edit/components/LandscapeTab/CreateSegment')
)

const SegmentList = Loadable(
  lazy(() =>
    import(
      '../features/asset/asset-edit/components/LandscapeTab/LandscapeSegmentList'
    )
  )
)

const EntityList = Loadable(
  lazy(() => import('../features/entity-tags/entity-tags-list'))
)

const EntityTagsNew = Loadable(
  lazy(() => import('../features/entity-tags/entity-tags-new/index'))
)

const EntityTagsEdit = Loadable(
  lazy(() => import('../features/entity-tags/entity-tags-edit/index'))
)

const IdentifierList = Loadable(
  lazy(() => import('../features/identifier/identifier-list'))
)

const IdentifierNew = Loadable(
  lazy(() => import('../features/identifier/identifier-new/index'))
)

const IdentifierEdit = Loadable(
  lazy(() => import('../features/identifier/identifier-edit'))
)

const CompositionTagsList = Loadable(
  lazy(() => import('../features/composition-tags/composition-tags-list/index'))
)

const CompositionTagsNew = Loadable(
  lazy(() => import('../features/composition-tags/composition-tags-new/index'))
)

const CompositionTagsEdit = Loadable(
  lazy(() => import('../features/composition-tags/composition-tags-edit/index'))
)
const CampaignsNew = Loadable(
  lazy(() => import('../features/campaigns/campaigns-new'))
)

const CampaignsList = Loadable(
  lazy(() => import('../features/campaigns/campaigns-list'))
)

const CampaignsEdit = Loadable(
  lazy(() => import('../features/campaigns/campaigns-edit'))
)

const SchemaList = Loadable(
  lazy(() => import('../features/schema/schema-list'))
)

const SchemaNew = Loadable(lazy(() => import('../features/schema/schema-new')))

const SchemaEdit = Loadable(
  lazy(() => import('../features/schema/schema-edit'))
)
const CustomerNew = Loadable(
  lazy(() => import('../features/customer/customer-new'))
)

const CustomerList = Loadable(
  lazy(() => import('../features/customer/customer-list'))
)

const CustomerEdit = Loadable(
  lazy(() => import('../features/customer/customer-edit'))
)

const MetadataList = Loadable(
  lazy(() => import('../features/metadata/metadata-list'))
)

const MetadataNew = Loadable(
  lazy(() => import('../features/metadata/metadata-new'))
)

const MetadataEdit = Loadable(
  lazy(() => import('../features/metadata/metadata-edit'))
)

const ClientRequestNew = Loadable(
  lazy(() => import('../features/client-request/client-request-new'))
)


const ClientRequestList = Loadable(
  lazy(() => import('../features/client-request/client-request-list'))
)


// video-library

const LipSynced = Loadable(
  lazy(() => import('../features/library-objects/lip-synced/index'))
)

const TextToSpeach = Loadable(
  lazy(() => import('../features/library-objects/text-to-speech/index'))
)

const VoiceCloned = Loadable(
  lazy(() => import('../features/library-objects/voice-cloned/index'))
)

const TransliterationService = Loadable(
  lazy(() => import('../features/library-objects/transliteration-service/index'))
)

const StaticKdens=Loadable(lazy(()=>import('../features/library-objects/static-kdens/index')))

const FinalVideos=Loadable(lazy(()=>import('../features/library-objects/final-videos/index')))

// usecase-config

const UsecaseConfigList = Loadable(
  lazy(() => import('../features/usecase-config/config-list'))
)

const UsecaseConfigNew = Loadable(
  lazy(() => import('../features/usecase-config/config-new'))
)

const UsecaseConfigEdit = Loadable(
  lazy(() => import('../features/usecase-config/config-edit'))
)

// usecase

const UsecaseList = Loadable(
  lazy(() => import('../features/usecase/usecase-list'))
)

const UsecaseNew = Loadable(
  lazy(() => import('../features/usecase/usecase-new'))
)

// workflow dag

const DagList = Loadable(
  lazy(() => import('../features/workflow-dag/dag-list'))
)

const DagAnalytics= Loadable(
  lazy(() => import('../features/workflow-dag/dag-analytics'))
)

const DagNew = Loadable(
  lazy(() => import('../features/workflow-dag/dag-new'))
)

const MltCreate = Loadable(
  lazy(() => import('../features/workflow-dag/mlt-create'))
)

const ViewWorkflow=Loadable(
  lazy(() => import('../features/workflow-dag/dag-view'))
)


const MainRoutes = ({ isLogged, userInfo, ...props }) => {
  // useEffect(() => {
  //   props.EnumDataAction()
  // }, [])
  return (
    <Routes>
      <Route element={<MainLayout userInfo={userInfo} />}>
        <Route
          path="/"
          element={
            <Protected isLoggedIn={isLogged}>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/dashboard"
          element={
            <Protected isLoggedIn={isLogged}>
              <Dashboard />
            </Protected>
          }
        />
        {/* PUBLIC ASSETS related ROUTES */}
        <Route
          path="/assign/role"
          element={
            <Protected isLoggedIn={isLogged}>
              <RolesPage />
            </Protected>
          }
        />
        <Route
          path="/assets/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <AssetList />
            </Protected>
          }
        />
        <Route
          path="/assets/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <AssetNewPage />
            </Protected>
          }
        />
        <Route
          path="/assets/bulk-add"
          element={
            <Protected isLoggedIn={isLogged}>
              <AssetBulkPage />
            </Protected>
          }
        />
        <Route
          path="/assets/metadata"
          element={
            <Protected isLoggedIn={isLogged}>
              <TagsForm />
            </Protected>
          }
        />
        <Route
          path="/assets/upload"
          element={
            <Protected isLoggedIn={isLogged}>
              <FileUpload />
            </Protected>
          }
        />
        <Route
          path="/assets/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <AssetInfoEdit />
            </Protected>
          }
        />

        <Route
          path="/assets/edit/:id/draft"
          element={
            <Protected isLoggedIn={isLogged}>
              <AssetEditPage />
            </Protected>
          }
        />
        <Route
          path="/assets/:id/analysing"
          element={
            <Protected isLoggedIn={isLogged}>
              <AnalysingPage />
            </Protected>
          }
        />
        <Route
          path="/assets/view/:id"
          element={
            <Protected isLoggedIn={isLogged}>
              <ViewAssets />
            </Protected>
          }
        />

        {/* IMAGE LIBRARY related ROUTES */}
        <Route
          path="/image/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <ImageList />
            </Protected>
          }
        />

        <Route
          path="/image/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <ImageEditPage />
            </Protected>
          }
        />

        {/* FONT LIBRARY related ROUTES */}
        <Route
          path="/font/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <FontList />
            </Protected>
          }
        />
        {/* 
        <Route
          path="/assets/edit/create-segment"
          element={
            <Protected isLoggedIn={isLogged}>
              <CreateSegment />
            </Protected>
          }
        />*/}
        <Route
          path="/assets/edit/:id"
          element={
            <Protected isLoggedIn={isLogged}>
              <PublicAssetTabs />
            </Protected>
          }
        />
        <Route
          path="/assets/edit/segment-list"
          element={
            <Protected isLoggedIn={isLogged}>
              <SegmentList />
            </Protected>
          }
        />
        {/* MY ASSETS related ROUTES */}
        <Route
          path="/myassets/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetList />
            </Protected>
          }
        />
        <Route
          path="/myassets/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetPage />
            </Protected>
          }
        />
        <Route
          path="/myassets/bulk-add"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetBulkPage />
            </Protected>
          }
        />
        <Route
          path="/myassets/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetInfoEdit />
            </Protected>
          }
        />
        <Route
          path="/myassets/metadata"
          element={
            <Protected isLoggedIn={isLogged}>
              <TagsForm />
            </Protected>
          }
        />
        <Route
          path="/myassets/upload"
          element={
            <Protected isLoggedIn={isLogged}>
              <FileUpload />
            </Protected>
          }
        />
        <Route
          path="/myassets/edit/:id/draft"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetEditPage />
            </Protected>
          }
        />
        <Route
          path="/myassets/:id/analysing"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetAnalysingPage />
            </Protected>
          }
        />
        <Route
          path="/myassets/edit/:id"
          element={
            <Protected isLoggedIn={isLogged}>
              <MyAssetInReviewPage />
            </Protected>
          }
        />
        {/* <Route
          path="/myassets/view/:id"
          element={
            <Protected isLoggedIn={isLogged}>
              <ViewAssets />
            </Protected>
          }
        />
      
        <Route
          path="/myassets/edit/segment-list"
          element={
            <Protected isLoggedIn={isLogged}>
              <SegmentList />
            </Protected>
          }
        />

         {/* UPCOMING EVENTS related ROUTES */}
        <Route
          path="/upcoming/events"
          element={
            <Protected isLoggedIn={isLogged}>
              <EventsPage />
            </Protected>
          }
        />

        {/* TRENDING TOPICS related ROUTES */}
        <Route
          path="/trending/topics"
          element={
            <Protected isLoggedIn={isLogged}>
              <TrendingTopicsPage />
            </Protected>
          }
        />

        {/* COPYRIGHT CONTENT related ROUTES */}
        <Route
          path="/copyright-content/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <ContentList />
            </Protected>
          }
        />
        <Route
          path="/copyright-content/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <ContentCreatePage />
            </Protected>
          }
        />

        <Route
          path="/copyright-content/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <ContentEditPage />
            </Protected>
          }
        />
        {/* CATEGORY and SUB-CATEGORY related ROUTES */}
        <Route
          path="/category/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <CategoryList />
            </Protected>
          }
        />
        <Route
          path="/subcategory/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <SubCategoryList />
            </Protected>
          }
        />
        <Route
          path="/subcategory/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <Subcategory />
            </Protected>
          }
        />
        {/* VENDOR related ROUTES */}
        <Route
          path="/vendor/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <VendorList />
            </Protected>
          }
        />

        {/* TEMPLATE related ROUTES */}
        <Route
          path="/templates/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateList />
            </Protected>
          }
        />
        <Route
          path="/templates/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateNew />
            </Protected>
          }
        />

        <Route
          path="/templates/edit/:id/draft"
          element={
            <Protected isLoggedIn={isLogged}>
              <DraftTemplatePage />
            </Protected>
          }
        />
        <Route
          path="/templates/add-source"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateNewSource />
            </Protected>
          }
        />
        <Route
          path="/templates/add-orientation"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateNewOrientation />
            </Protected>
          }
        />
        <Route
          path="/templates/events"
          element={
            <Protected isLoggedIn={isLogged}>
              <UpcomingTemplateList />
            </Protected>
          }
        />
        

        <Route
          path="/template/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <UpcomingTemplateEdit />
            </Protected>
          }
        />

        <Route
          path="/template/:id/instance/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateInstances />
            </Protected>
          }
        />

        <Route
          path="/template/:id/instance/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateInstanceAdd />
            </Protected>
          }
        />

        <Route
          path="/templates/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <TemplateInfoEdit />
            </Protected>
          }
        />

        {/* Tags related ROUTES */}
        <Route
          path="/entity-tags/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <EntityList />
            </Protected>
          }
        />

        <Route
          path="/entity-tags/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <EntityTagsNew />
            </Protected>
          }
        />
        <Route
          path="/entity-tags/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <EntityTagsEdit />
            </Protected>
          }
        />

        {/*Identifier related ROUTES */}

        <Route
          path="/identifier/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <IdentifierList />
            </Protected>
          }
        />

        <Route
          path="/identifier/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <IdentifierNew />
            </Protected>
          }
        />

        <Route
          path="/identifier/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <IdentifierEdit />
            </Protected>
          }
        />

        {/*Compostion tags related ROUTES */}

        <Route
          path="/composition-tags/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <CompositionTagsList />
            </Protected>
          }
        />

        <Route
          path="/composition-tags/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <CompositionTagsNew />
            </Protected>
          }
        />

        <Route
          path="/composition-tags/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <CompositionTagsEdit />
            </Protected>
          }
        />

        <Route
          path="/schema/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <SchemaList />
            </Protected>
          }
        />

        <Route
          path="/schema/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <SchemaNew />
            </Protected>
          }
        />

        <Route
          path="/schema/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <SchemaEdit />
            </Protected>
          }
        />

        <Route
          path="/customer/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <CustomerList />
            </Protected>
          }
        />

        <Route
          path="/customer/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <CustomerNew />
            </Protected>
          }
        />

        <Route
          path="/customer/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <CustomerEdit />
            </Protected>
          }
        />
        <Route
          path="/metadata/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <MetadataList />
            </Protected>
          }
        />

        <Route
          path="/metadata/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <MetadataNew />
            </Protected>
          }
        />

        <Route
          path="/metadata/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <MetadataEdit />
            </Protected>
          }
        />

        <Route
          path="/client/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <ClientRequestNew />
            </Protected>
          }
        />


        <Route
          path="/client/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <ClientRequestList />
            </Protected>
          }
        />

        {/*Campaigns related ROUTES */}

        <Route
          path="/campaigns/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <CampaignsNew />
            </Protected>
          }
        />

        <Route
          path="/campaigns/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <CampaignsList />
            </Protected>
          }
        />

        <Route
          path="/campaigns/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <CampaignsEdit />
            </Protected>
          }
        />

        {/* OTHER ROUTES */}
        <Route
          path="/clip/upload"
          element={
            <Protected isLoggedIn={isLogged}>
              <ClipMetaData />
            </Protected>
          }
        />
        <Route
          path="/upload/videoclip"
          element={
            <Protected isLoggedIn={isLogged}>
              <UploadVideoClip />
            </Protected>
          }
        />
        <Route
          path="/sample-page"
          element={
            <Protected isLoggedIn={isLogged}>
              <SamplePage />
            </Protected>
          }
        />

        <Route
          path="/video-library/transliteration-service"
          element={
            <Protected isLoggedIn={isLogged}>
              <TransliterationService />
            </Protected>
          }
        />

        <Route
          path="/video-library/text-to-speech"
          element={
            <Protected isLoggedIn={isLogged}>
              <TextToSpeach />
            </Protected>
          }
        />

        <Route
          path="/video-library/lip-synced"
          element={
            <Protected isLoggedIn={isLogged}>
              <LipSynced />
            </Protected>
          }
        />



        <Route
          path="/video-library/voice-cloned"
          element={
            <Protected isLoggedIn={isLogged}>
              <VoiceCloned />
            </Protected>
          }
        />

        <Route
        path="/video-library/static/kden"
        element={
          <Protected isLoggedIn={isLogged}>
            <StaticKdens />
          </Protected>
        }
      />


      <Route
      path="/video-library/final/videos"
      element={
        <Protected isLoggedIn={isLogged}>
          <FinalVideos />
        </Protected>
      }
    />

        <Route
          path="/usecase-config/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <UsecaseConfigList />
            </Protected>
          }
        />

        <Route
          path="/usecase-config/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <UsecaseConfigNew />
            </Protected>
          }
        />

        <Route
          path="/usecase-config/:id/edit"
          element={
            <Protected isLoggedIn={isLogged}>
              <UsecaseConfigEdit />
            </Protected>
          }
        />

        <Route
          path="/usecase/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <UsecaseList />
            </Protected>
          }
        />

        <Route
          path="/usecase/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <UsecaseNew />
            </Protected>
          }
        />

        <Route
          path="/dag/list"
          element={
            <Protected isLoggedIn={isLogged}>
              <DagList />
            </Protected>
          }
        />

        <Route
        path="/dag/view/analytics"
        element={
          <Protected isLoggedIn={isLogged}>
            <DagAnalytics />
          </Protected>
        }
      />

      <Route
        path="/dag/view/:workflowname"
        element={
          <Protected isLoggedIn={isLogged}>
            <ViewWorkflow />
          </Protected>
        }
      />

        <Route
          path="/dag/add"
          element={
            <Protected isLoggedIn={isLogged}>
              <DagNew />
            </Protected>
          }
        />

        <Route
          path="/dag/create"
          element={
            <Protected isLoggedIn={isLogged}>
              <MltCreate />
            </Protected>
          }
        />

      </Route>

      <Route path="/login" element={<Navigate to="/" replace />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

const mapStateToProps = () => ({})
const mapActionsToProps = {
  EnumDataAction,
}

export default connect(mapStateToProps, mapActionsToProps)(MainRoutes)
